window.jQuery = window.$ = require('jquery');
window.$.fn.dataTable = require('datatables');
window._ = require('underscore');
window.Highcharts = require('highcharts');
require('highcharts/highcharts-more')(Highcharts);
window.moment = require('moment-timezone');
window.Backbone = require('imports-loader?this=>window!backbone');
window.Backbone.Syphon = require('imports-loader?this=>window!backbone.syphon');
window.i18next = require('i18next');

import highchartsAccessibility from 'highcharts/modules/accessibility';
import addExportData from 'highcharts/modules/export-data';
import enableExporting from 'highcharts/modules/exporting';
import addOfflineExportingModule from 'highcharts/modules/offline-exporting';

highchartsAccessibility(Highcharts);
addExportData(Highcharts);
enableExporting(Highcharts);
addOfflineExportingModule(Highcharts);

require('imports-loader?define=>false!jquery-ui-dist/jquery-ui.js');
require('imports-loader?define=>false!jquery-ui-timepicker-addon/dist/jquery-ui-timepicker-addon.js');
require('imports-loader?define=>false!backbone.marionette/lib/backbone.marionette.js');
